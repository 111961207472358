import ManageAccountIcon from '@mui/icons-material/ManageAccounts';
import {Avatar, Box, Button, Divider, IconButton, MenuItem, Popover} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {useContext, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {useAccountMenu} from '../../hooks/layout/account-menu.hook';
import {UserContext} from '../../context/user-context';

export const AccountMenu = () => {
  const anchorRef = useRef(null);
  const {isOpened, toggleOpened} = useAccountMenu();
  const {setUserToken} = useContext(UserContext);

  return (
    <Box>
      <IconButton
        ref={anchorRef}
        onClick={toggleOpened}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
        }}
      >
        <Avatar sx={{bgcolor: 'grey'}} />
      </IconButton>

      <Popover
        open={isOpened}
        onClose={toggleOpened}
        sx={{width: 240}}
        anchorEl={anchorRef.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.5,
            overflow: 'inherit',
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.3)}`,
            width: 240,
          },
        }}
      >
        <MenuItem
          to={''}
          component={NavLink}
          onClick={() => {
            alert('Coming soon!');
          }}
          sx={{typography: 'body2', py: 1, px: 2.5}}
        >
          <Box
            component={ManageAccountIcon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          My account
        </MenuItem>

        <Divider sx={{my: 1}} />

        <Box sx={{p: 2, pt: 1.5}}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => {
              setUserToken('');
            }}
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};
