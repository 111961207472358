import {ChangeEvent, FormEvent, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {login} from '../../services/auth.service';
import {UserContext} from '../../context/user-context';
import {LoginResponseType} from '../../types/login-response.type';

export const useLoginForm = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, updateLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const {setUserToken} = useContext(UserContext);
  let navigate = useNavigate();

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      updateLoading(true);
      const data = (await login(email, password)) as LoginResponseType;
      setUserToken(data.data.token);
      navigate('/identity-users');
    } catch (e: unknown) {
      const error = e as Error;
      console.error(e);
      setError(error.message);
    } finally {
      updateLoading(false);
    }
  };

  return {
    email,
    password,
    onEmailChange,
    onPasswordChange,
    submit,
    isLoading,
    error,
  };
};
