import {useDataTable} from '../data-table.hook';
import {getOrders} from '../../services/payment.service';

export const usePaymentList = () => {
  const {
    rows,
    total,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading,
    error,
    handleSubmitFilters,
  } = useDataTable(getOrders);

  return {
    rows,
    total,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSubmitFilters,
    isLoading,
    error,
  };
};
