import {useState} from 'react';

export const useAccountMenu = () => {
  const [isOpened, updateOpened] = useState(false);

  const toggleOpened = () => {
    updateOpened(!isOpened);
  };

  return {isOpened, toggleOpened};
};
