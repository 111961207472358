export type RequestOptions = {
  base: string | undefined;
  headers?: {[key: string]: string};
  type?: 'json' | 'blob' | 'text';
};

export const get = async (url: string, options: RequestOptions): Promise<unknown> => {
  const response = await fetch(`${options.base}${url}`, {
    headers: options.headers,
  });

  if (!response.ok) {
    const text = await response.text();

    throw new Error(text);
  }

  switch (options.type) {
    case 'blob':
      return response.blob();
    case 'text':
      return response.text();
    default:
      return response.json();
  }
};

const defaultHeaders = {'content-type': 'application/json'};

export const post = async (url: string, data: unknown = {}, options: RequestOptions): Promise<unknown> => {
  const response = await fetch(`${options.base}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {...defaultHeaders, ...options.headers},
  });

  if (!response.ok) {
    const text = await response.text();

    throw new Error(text);
  }

  switch (options.type) {
    case 'blob':
      return response.blob();
    case 'text':
      return response.text();
    default:
      return response.json();
  }
};

export const del = async (url: string, data: unknown = {}, options: RequestOptions): Promise<unknown> => {
  const response = await fetch(`${options.base}${url}`, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {...defaultHeaders, ...options.headers},
  });

  if (!response.ok) {
    const text = await response.text();

    throw new Error(text);
  }

  return response.json();
};
