import {get} from './request';
import {PaymentOrdersResponseType} from '../types/payment-orders-response.type';
import {PaymentOrdersFiltersType} from '../types/payment-orders-filters.type';
import {firstLetterLowercase} from '../helpers/string-helper';

const DASHBOARD_SERVICE_BASE = process.env.REACT_APP_DASHBOARD_SERVICE_API_URL;

export const getOrders = async (
  page: number,
  limit: number,
  token: string,
  searchParams: PaymentOrdersFiltersType | null,
): Promise<PaymentOrdersResponseType> => {
  const query = new URLSearchParams();
  query.append('page', String(page));
  query.append('limit', String(limit));

  if (searchParams) {
    if (searchParams['createdAtFrom'] && searchParams['createdAtTo']) {
      query.append('createdAt', JSON.stringify([searchParams['createdAtFrom'], searchParams['createdAtTo']]));
    }

    Object.keys(searchParams).forEach((paramKey) => {
      const value = searchParams[paramKey as keyof PaymentOrdersFiltersType];
      if (!value || value === 'all' || ['createdAtFrom', 'createdAtTo'].includes(paramKey)) {
        return;
      }

      if (paramKey.startsWith('metadata')) {
        query.append(`metadata[${firstLetterLowercase(paramKey.replace('metadata', ''))}]`, String(value));

        return;
      }
      query.append(paramKey, String(value));
    });
  }

  return get(`/payment/orders?${query}`, {
    base: DASHBOARD_SERVICE_BASE,
    headers: {Authorization: `Bearer ${token}`},
  }) as Promise<PaymentOrdersResponseType>;
};
