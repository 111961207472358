import React, {useContext} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, Divider, StyledEngineProvider} from '@mui/material';
import {Login} from './routes/login/login';
import {IdentityUsers} from './routes/identity/identity-users';
import {defaultTheme} from './theme';
import {Header} from './components/layout/header';
import {UserContext} from './context/user-context';
import {PaymentOrders} from './routes/payment/payment-orders';
import {IdentitySubscriptions} from './routes/identity/identity-subscriptions';

function RequireAuth({children}: {children: JSX.Element}) {
  let location = useLocation();
  const {userToken} = useContext(UserContext);

  if (!userToken) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }

  return children;
}

export const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Header />
        <Divider sx={{mb: 11}} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/identity-users"
            element={
              <RequireAuth>
                <IdentityUsers />
              </RequireAuth>
            }
          />
          <Route
            path="/identity-subscriptions"
            element={
              <RequireAuth>
                <IdentitySubscriptions />
              </RequireAuth>
            }
          />
          <Route
            path="/payment-orders"
            element={
              <RequireAuth>
                <PaymentOrders />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Navigate to="/identity-users" replace />} />
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
