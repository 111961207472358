import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {LayoutWithMenu} from '../../components/layout/layout-with-menu';
import {TABLE_PER_ROW_OPTIONS} from '../../consts/table.const';
import {useIdentityList} from '../../hooks/identity/identity-list.hook';
import {Alert, Box, Chip, CircularProgress} from '@mui/material';

export const IdentityUsers = () => {
  const {error, rows, rowsPerPage, handleChangePage, handleChangeRowsPerPage, page, total, isLoading} =
    useIdentityList();

  return (
    <LayoutWithMenu title="Users">
      <>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Paper sx={{width: '100%'}}>
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          )}
          <TableContainer sx={{height: 700}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{minWidth: 50}}>ID</TableCell>
                  <TableCell style={{minWidth: 200}}>Auth Methods</TableCell>
                  <TableCell style={{minWidth: 200}}>Non custody wallets</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        {row.authMethods.map((authMethod) => (
                          <Box key={authMethod.id}>
                            <Chip
                              label={`${authMethod.type} | ${authMethod.email} | ${new Date(
                                authMethod.createdAt,
                              ).toLocaleDateString()}`}
                              variant="outlined"
                            />
                          </Box>
                        ))}
                      </TableCell>
                      <TableCell>
                        {row.nonCustodyWallets.map((wallet) => (
                          <Box key={wallet.id}>
                            <Chip
                              label={`${wallet.type} | ${wallet.publicKey} | ${new Date(
                                wallet.createdAt,
                              ).toLocaleDateString()}`}
                              variant="outlined"
                            />
                          </Box>
                        ))}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PER_ROW_OPTIONS}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    </LayoutWithMenu>
  );
};
