import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {LayoutWithMenu} from '../../components/layout/layout-with-menu';
import {TABLE_PER_ROW_OPTIONS} from '../../consts/table.const';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {usePaymentList} from '../../hooks/payment/payment-list.hook';
import {PaymentUsdToNftMetadata, StatusHistoryType} from '../../types/payment-order.type';
import {PaymentOrdersFiltersType} from '../../types/payment-orders-filters.type';
import {useMemo} from 'react';
import {Field, Form} from 'react-final-form';
import {PaymentStatusEnum} from 'services-types';
import {LocalizationProvider} from '@mui/lab';

export const PaymentOrders = () => {
  const {
    error,
    rows,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    total,
    isLoading,
    handleSubmitFilters,
  } = usePaymentList();

  const initialValues = useMemo<PaymentOrdersFiltersType>(
    () => ({
      status: 'all',
      sellerEthAddress: '',
      buyerEthAddress: '',
      metadataNftId: '',
      metadataBuyerEmail: '',
      metadataEventId: '',
      createdAtFrom: new Date(),
      createdAtTo: new Date(),
    }),
    [],
  );

  const renderMetadataValue = (
    metadataKey: keyof PaymentUsdToNftMetadata,
    value: PaymentUsdToNftMetadata[keyof PaymentUsdToNftMetadata],
  ) => {
    if (metadataKey === 'statusHistory') {
      const statusHistory = value as StatusHistoryType[];

      return statusHistory.map(
        (statusItem) => `${statusItem.status}: ${new Date(statusItem.date).toLocaleDateString()}`,
      );
    }

    return value;
  };

  const statuses = Object.values(PaymentStatusEnum);
  const dateFormat = 'MM/dd/yyyy';

  return (
    <LayoutWithMenu title="Payments">
      <>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Paper sx={{width: '100%'}}>
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          )}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Form initialValues={initialValues} onSubmit={handleSubmitFilters}>
                {({handleSubmit}) => (
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Field name="status">
                      {({input}) => (
                        <Box pr="10px">
                          <TextField
                            style={{minWidth: '150px'}}
                            id="outlined-select-currency"
                            select
                            label="Status"
                            {...input}
                          >
                            <MenuItem key={'all'} value={'all'}>
                              all
                            </MenuItem>
                            {statuses.map((statusItem) => (
                              <MenuItem key={statusItem} value={statusItem}>
                                {statusItem}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      )}
                    </Field>
                    <Field name="sellerEthAddress">
                      {({input}) => (
                        <Box pr="10px">
                          <TextField id="outlined-basic" label="Seller address" variant="outlined" {...input} />
                        </Box>
                      )}
                    </Field>

                    <Field name="buyerEthAddress">
                      {({input}) => (
                        <Box pr="10px">
                          <TextField id="outlined-basic" label="Buyer address" variant="outlined" {...input} />
                        </Box>
                      )}
                    </Field>

                    <Field name="metadataNftId">
                      {({input}) => (
                        <Box pr="10px">
                          <TextField id="outlined-basic" label="NFT ID" variant="outlined" {...input} />
                        </Box>
                      )}
                    </Field>

                    <Field name="metadataEventId">
                      {({input}) => (
                        <Box pr="10px">
                          <TextField id="outlined-basic" label="Event ID" variant="outlined" {...input} />
                        </Box>
                      )}
                    </Field>

                    <Field name="metadataBuyerEmail">
                      {({input}) => (
                        <Box pr="10px">
                          <TextField id="outlined-basic" label="Buyer Email" variant="outlined" {...input} />
                        </Box>
                      )}
                    </Field>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Field name="createdAtFrom" type="text">
                        {({input}) => (
                          <MobileDatePicker
                            inputFormat={dateFormat}
                            {...input}
                            renderInput={(params) => (
                              <Box pr="10px">
                                <TextField {...params} label="Created At (from)" />
                              </Box>
                            )}
                          />
                        )}
                      </Field>
                      <Field name="createdAtTo" type="text">
                        {({input}) => (
                          <MobileDatePicker
                            inputFormat={dateFormat}
                            {...input}
                            renderInput={(params) => (
                              <Box pr="10px">
                                <TextField {...params} label="Created At (to)" />
                              </Box>
                            )}
                          />
                        )}
                      </Field>
                    </LocalizationProvider>

                    <Box>
                      <Button
                        title="Submit"
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={handleSubmit}
                      >
                        Filter
                      </Button>
                    </Box>
                  </Box>
                )}
              </Form>
            </AccordionDetails>
          </Accordion>

          <TableContainer sx={{height: 700}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{minWidth: 50}}>ID</TableCell>
                  <TableCell style={{minWidth: 200}}>Buyer Address</TableCell>
                  <TableCell style={{minWidth: 200}}>Seller Address</TableCell>
                  <TableCell style={{minWidth: 200}}>Status</TableCell>
                  <TableCell style={{minWidth: 200}}>Metadata</TableCell>
                  <TableCell style={{minWidth: 200}}>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.buyerEthAddress}</TableCell>
                      <TableCell>{row.sellerEthAddress}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        {Object.keys(row.metadata).map((metadataKey) => {
                          let value = row.metadata[metadataKey as keyof PaymentUsdToNftMetadata];
                          if (!value) {
                            return null;
                          }

                          return (
                            <Box key={`${row.id}-${metadataKey}`}>
                              <Chip
                                label={`${metadataKey}: ${renderMetadataValue(
                                  metadataKey as keyof PaymentUsdToNftMetadata,
                                  value,
                                )}`}
                                variant="outlined"
                              />
                            </Box>
                          );
                        })}
                      </TableCell>
                      <TableCell>{row.createdAt}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PER_ROW_OPTIONS}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    </LayoutWithMenu>
  );
};
