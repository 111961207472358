import {Box, Drawer, ListItem, ListItemIcon, ListItemText, List, Typography} from '@mui/material';
import {useNavigate, useLocation} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {SupervisedUserCircle as UserIcon} from '@mui/icons-material';
import {Payment as PaymentIcon} from '@mui/icons-material';
import {Subscriptions as SubscriptionsIcon} from '@mui/icons-material';
import {DRAWER_WIDTH} from '../../consts/layout.const';

const useStyles = makeStyles(() => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    marginTop: '100px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  content: {
    flexGrow: 1,
    padding: 12,
    marginLeft: DRAWER_WIDTH,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    paddingBottom: '15px',
  },
}));

const menuItems = [
  {
    title: 'Users',
    url: '/identity-users',
    icon: UserIcon,
  },
  {
    title: 'Subscriptions',
    url: '/identity-subscriptions',
    icon: SubscriptionsIcon,
  },
  {
    title: 'Payments',
    url: '/payment-orders',
    icon: PaymentIcon,
  },
];

export const LayoutWithMenu = ({children, title}: {children: JSX.Element; title: string}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const onMenuClick = (path: string) => {
    navigate(path);
  };

  return (
    <Box>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerOpen,
        }}
        open={true}
      >
        <div className={classes.toolbar} />
        <List>
          {menuItems.map(({title, url, icon: Icon}) => (
            <ListItem button onClick={onMenuClick.bind(null, url)} key={url} selected={location.pathname === url}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box className={classes.content}>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};
