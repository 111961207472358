import {useDataTable} from '../data-table.hook';
import {getSubscriptions} from '../../services/identity.service';

export const useIdentitySubscriptions = () => {
  const {rows, total, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, isLoading, error} =
    useDataTable(getSubscriptions);

  return {
    rows,
    total,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading,
    error,
  };
};
