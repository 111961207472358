import {post} from './request';

const DASHBOARD_SERVICE_BASE = process.env.REACT_APP_DASHBOARD_SERVICE_API_URL;

export const login = (username: string, password: string) => {
  return post(
    `/user/login`,
    {
      username,
      password,
    },
    {base: DASHBOARD_SERVICE_BASE},
  );
};
