import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {LayoutWithMenu} from '../../components/layout/layout-with-menu';
import {TABLE_PER_ROW_OPTIONS} from '../../consts/table.const';
import {Alert, Box, CircularProgress} from '@mui/material';
import {useIdentitySubscriptions} from '../../hooks/identity/subscription-list.hook';

export const IdentitySubscriptions = () => {
  const {error, rows, rowsPerPage, handleChangePage, handleChangeRowsPerPage, page, total, isLoading} =
    useIdentitySubscriptions();

  return (
    <LayoutWithMenu title="Subscriptions">
      <>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Paper sx={{width: '100%'}}>
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          )}
          <TableContainer sx={{height: 700}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{minWidth: 200}}>Entity ID</TableCell>
                  <TableCell style={{minWidth: 50}}>Email</TableCell>
                  <TableCell style={{minWidth: 200}}>Type</TableCell>
                  <TableCell style={{minWidth: 200}}>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{row.entityId}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={TABLE_PER_ROW_OPTIONS}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </>
    </LayoutWithMenu>
  );
};
