import {get} from './request';
import {IdentityUsersResponseType} from '../types/identity-users-response.type';
import {IdentitySubscriptionsResponseType} from '../types/identity-subscriptions-response.type';

const DASHBOARD_SERVICE_BASE = process.env.REACT_APP_DASHBOARD_SERVICE_API_URL;

export const getUsers = async (page: number, limit: number, token: string): Promise<IdentityUsersResponseType> => {
  return get(`/identity/users?page=${page}&limit=${limit}`, {
    base: DASHBOARD_SERVICE_BASE,
    headers: {Authorization: `Bearer ${token}`},
  }) as Promise<IdentityUsersResponseType>;
};

export const getSubscriptions = async (
  page: number,
  limit: number,
  token: string,
): Promise<IdentitySubscriptionsResponseType> => {
  return get(`/identity/subscriptions?page=${page}&limit=${limit}`, {
    base: DASHBOARD_SERVICE_BASE,
    headers: {Authorization: `Bearer ${token}`},
  }) as Promise<IdentitySubscriptionsResponseType>;
};
