import {AppBar, Box, Button, Toolbar, Typography} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';

import {ReactComponent as Logo} from '../../assets/logo.svg';
import {AccountMenu} from './account-menu';
import {UserContext} from '../../context/user-context';
import {useContext} from 'react';

export const Header = () => {
  const {userToken} = useContext(UserContext);

  return (
    <AppBar sx={{backgroundColor: 'background.paper', zIndex: 1}}>
      <Box component={Toolbar} display="flex" justifyContent="space-between">
        <RouterLink to={'/'}>
          <Box sx={{display: 'inline-flex', verticalAlign: 'middle'}}>
            <Logo height={40} width={100} />
          </Box>
        </RouterLink>
        <Typography color="black">Davinci Dashboard</Typography>
        {userToken ? (
          <AccountMenu />
        ) : (
          <>
            <Button variant="outlined" onClick={() => {}}>
              Login
            </Button>
          </>
        )}
      </Box>
    </AppBar>
  );
};
