import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {ApiResponseType} from '../types/api-response.type';
import {TABLE_PER_ROW_OPTIONS} from '../consts/table.const';
import {UserContext} from '../context/user-context';

export const useDataTable = <T, V>(
  apiService: (page: number, limit: number, userToken: string, searchParams: V | null) => Promise<ApiResponseType<T>>,
) => {
  type DataTableState = {
    error: string;
    isLoading: boolean;
    rows: T[];
    total: number;
    page: number;
    rowsPerPage: number;
    filters: V | null;
  };

  const {userToken} = useContext(UserContext);
  const [dataTableState, setDataTableState] = useState<DataTableState>({
    error: '',
    isLoading: false,
    rows: [],
    total: 0,
    page: 0,
    rowsPerPage: TABLE_PER_ROW_OPTIONS[0],
    filters: null,
  });

  const loadPage = async () => {
    try {
      setDataTableState({...dataTableState, isLoading: true});
      const response = await apiService(
        dataTableState.page,
        dataTableState.rowsPerPage,
        userToken,
        dataTableState.filters,
      );
      setDataTableState({...dataTableState, rows: response.data.records, total: response.data.total});
    } catch (e: unknown) {
      const error = e as Error;
      console.error(e);
      setDataTableState({...dataTableState, error: error.message});
    } finally {
      setDataTableState((state) => ({...state, isLoading: false}));
    }
  };

  useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableState.page, dataTableState.rowsPerPage, dataTableState.filters]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setDataTableState({...dataTableState, page: newPage});
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event) {
      return;
    }

    const newRowsPerPage = +event.target.value;
    setDataTableState({...dataTableState, rowsPerPage: newRowsPerPage, page: 0});
  };

  const handleSubmitFilters = (filters: V) => {
    setDataTableState({...dataTableState, filters});
  };

  return {
    rows: dataTableState.rows,
    total: dataTableState.total,
    page: dataTableState.page,
    rowsPerPage: dataTableState.rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSubmitFilters,
    isLoading: dataTableState.isLoading,
    error: dataTableState.error,
  };
};
