import {Box, Button, FormLabel, TextField, Container, CircularProgress, Alert, Card} from '@mui/material';
import {useLoginForm} from '../../hooks/login/login.hook';

export const Login = () => {
  const {email, password, onEmailChange, onPasswordChange, submit, isLoading, error} = useLoginForm();

  return (
    <Box sx={{padding: '0 24px', marginTop: 3}}>
      <Container maxWidth="xs">
        <Card>
          <Box sx={{my: 5}} m={2}>
            <form onSubmit={submit}>
              <Box sx={{my: 4, alignItems: 'center'}}>
                <FormLabel>
                  <Box sx={{display: 'inline-block', mb: 1, ml: 1}}>E-mail</Box>
                  <TextField
                    color="primary"
                    fullWidth
                    onChange={onEmailChange}
                    size={'medium'}
                    type={'text'}
                    value={email}
                    variant="outlined"
                  />
                </FormLabel>
              </Box>

              <Box sx={{my: 4, alignItems: 'center'}}>
                <FormLabel>
                  <Box sx={{display: 'inline-block', mb: 1, ml: 1}}>Password</Box>
                  <TextField
                    color="primary"
                    fullWidth
                    onChange={onPasswordChange}
                    size={'medium'}
                    type={'password'}
                    value={password}
                    variant="outlined"
                  />
                </FormLabel>
              </Box>

              {error && (
                <Box mb={4}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}

              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button title="Submit" type="submit" color="primary" variant="contained" size="large">
                  Submit{' '}
                  {isLoading && (
                    <Box ml={1} display="flex" justifyContent="center">
                      <CircularProgress color="inherit" size="1em" />
                    </Box>
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};
