import {useMemo, useEffect, useState, ReactElement, createContext, ReactNode} from 'react';
import {CircularProgress} from '@mui/material';

export type UserContextType = {
  userToken: string;
  setUserToken: Function;
};

interface Provider {
  children: ReactNode;
}

export const UserContext = createContext<UserContextType>({
  userToken: '',
  setUserToken: () => {},
});

const USER_TOKEN_KEY = 'USER_TOKEN_KEY';

export function UserContextProvider({children}: Provider): ReactElement {
  const [userToken, setUserToken] = useState<string>('');
  const [isLoading, updateLoading] = useState<boolean>(true);

  const checkAuth = () => {
    updateLoading(true);

    const token = localStorage.getItem(USER_TOKEN_KEY);
    if (token) {
      setUserToken(token);
    }

    updateLoading(false);
  };

  const setUserTokenWrapper = (value: string) => {
    localStorage.setItem(USER_TOKEN_KEY, value);
    setUserToken(value);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const context = useMemo(
    () => ({
      userToken,
      setUserToken: setUserTokenWrapper,
    }),
    [userToken],
  );

  return (
    <UserContext.Provider value={context}>
      {isLoading ? <CircularProgress color="secondary" /> : children}
    </UserContext.Provider>
  );
}
